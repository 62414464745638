.react-player {
  text-align: center;
  margin: auto;
  min-width: 900px;
}

.react-player > video {
  border-radius: 15px;
  min-width: 500px;
  text-align: center;
  margin: auto;
}
